

























































































































































































import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IOrganizations, IProduct, ISMSCredentials } from '@/types/types';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { image, max, min, numeric, required } from 'vee-validate/dist/rules';
import { VueEditor } from 'vue2-editor';
// Import the plugin code
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import { FilePondFile } from 'filepond';
import WidgetMixins from '@/mixins/WidgetMixins';
import { Watch } from 'vue-property-decorator';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid amount provided',
});
extend('max', {
  ...max,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: 'Invalid phone number provided',
});
extend('image', {
  ...image,
  message: 'Input must be an image',
});
const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode,
  FilePondPluginImageTransform,
  FilePondPluginFileMetadata,
);
const organizationModule = namespace('organizations');
@Component({
  name: 'Create',
  components: {
    FilePond,
    VueEditor,
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    Notifications: loadWidget('pages/Notifications'),
    ValidationObserver,
    ValidationProvider,
    ProgressLoader: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Create extends mixins(WidgetMixins) {
  @organizationModule.Getter('getAllOrganizations') organizations!: IOrganizations[];
  @organizationModule.Getter('getOrganizationDetails') organizationDetails!: IOrganizations & {
    smsCredentials: ISMSCredentials;
  };
  search: string = '';
  toolbar: Array<Array<string | object>> = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'code-block', 'blockquote'],
  ];

  rules: any = [(value: any) => !value || value.size < 1000000 || 'Avatar size should be less than 1 MB!'];
  myFile: [] = [];
  organization: Pick<IOrganizations, 'name' | 'emailAddress' | 'contact'> = {
    name: '',
    emailAddress: '',
    contact: '',
  };

  sms: any = {
    smsApiKey: '',
    smsApiSecret: '',
  };
  smsCopy: any = {
    smsApiKey: '',
    smsApiSecret: '',
  };

  images: File[] = [];

  getFile() {}

  get filteredOrganizations() {
    return this.organizations.filter((organization: IOrganizations) => {
      return organization.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
    });
  }

  @Watch('organizationDetails')
  onOrganizationDetailsChange(payload: IOrganizations & { smsCredentials: ISMSCredentials }): void {
    const { smsCredentials, ...rest } = payload;
    if (this.$route.query.organizationId) {
      this.organization = {
        _id: this.$route.query.organizationId,
        ...rest,
      };
      this.sms = {
        smsApiKey: smsCredentials?.smsApiKey,
        smsApiSecret: smsCredentials?.smsApiSecret,
      };
      this.smsCopy = {
        smsApiKey: smsCredentials?.smsApiKey,
        smsApiSecret: smsCredentials?.smsApiSecret,
      };
    }
  }
  addOrganization() {
    const payload = {
      organization: this.organization,
      sms: this.sms,
      smsCopy: this.smsCopy,
      isUpdating: !!this.$route.query.organizationId,
    };
    this.$store.dispatch('organizations/create', payload);
  }
  created(): void {
    if (this.$route.query.organizationId) {
      this.$store.dispatch('organizations/details', this.$route.query.organizationId);
      return;
    }
    this.$store.dispatch('organizations/list', false);
  }
}
